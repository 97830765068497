<template>
  <v-dialog v-model="opened" max-width="800" scrollable>
    <v-card>
      <v-card-title>
        {{ $t("ui_editing") }}
      </v-card-title>
      <v-card-text>
        <AppTextarea
          v-for="(field, i) in fieldsEdit"
          :key="i"
          v-model="data[field]"
          :label="field"
          :rows="rows || '6'"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="red" text @click="close">
          {{ $t("ui_cancel") }}
        </v-btn>

        <v-btn color="primary" text @click="save">
          {{ $t("ui_save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
function formatJson(data, jsonMethod = "stringify") {
  const _data = {};
  for (let key in data) {
    const v = data[key];
    if (!v) {
      _data[key] = v;
      continue;
    }
    if (typeof v === "object" || v.startsWith("[") || v.startsWith("{")) {
      _data[key] = JSON[jsonMethod](v, null, 4);
    } else {
      _data[key] = v;
    }
  }
  return _data;
}

export default {
  props: ["fieldsEdit", "rows"],
  data() {
    return {
      opened: false,
      data: {},
    };
  },
  methods: {
    open(data) {
      this.data = Object.assign({}, formatJson(data));
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.data = {};
    },
    save() {
      let data = {};
      try {
        data = formatJson(this.data, "parse");
      } catch (e) {
        this.$alert({ text: this.$t("json_not_valid"), type: "warning" });
        return;
      }

      this.$emit("onSave", data);
      this.close();
    },
  },
};
</script>
