<template>
  <v-menu v-model="opened">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="indigo" text x-small v-bind="attrs" v-on="on">
        <v-icon>mdi-arrow-collapse-horizontal</v-icon>
      </v-btn>
    </template>

    <v-list>
      <v-subheader>{{ title }}</v-subheader>
      <v-list-item v-for="(row, i) in rows" :key="i" @click="save(row)">
        <v-list-item-title>{{ row[fieldName] || row["id"] }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: ["rows", "fieldName", "currentRow", "title"],
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    save(row) {
      this.$emit("onSave", { currentRow: this.currentRow, row });
    },
  },
};
</script>
